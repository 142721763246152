import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import ChevronL from "../images/chevron_l.svg"
import ChevronR from "../images/chevron_r.svg"
import "pure-react-carousel/dist/react-carousel.es.css"
import { CarouselClientsStyle } from "../styles/carouselClients"

const CarouselClients = props => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCarouselClients {
        totalCount
        edges {
          node {
            title
            image {
              file {
                url
              }
            }
            contentText
            smallTitle
          }
        }
      }
    }
  `)

  return (
    <CarouselClientsStyle>
      <CarouselProvider
        naturalSlideWidth={props.naturalSlideWidth}
        naturalSlideHeight={props.naturalSlideHeight}
        visibleSlides={props.visibleSlides}
        totalSlides={data.allContentfulCarouselClients.totalCount}
      >
        <Slider role="list">
          {data.allContentfulCarouselClients.edges.map((edge, index) => {
            return (
              <Slide
                role="listitem"
                index={index}
                key={"slide" + index}
                className={"slide"}
              >
                <div key={"logoClient" + index} className={"logoClient"}>
                  <Link
                    to="/clientes"
                    aria-label="Conheça os nossos clientes..."
                  >
                    <img src={edge.node.image.file.url} alt={edge.node.title} />
                  </Link>
                </div>
                <p key={"slideDesc" + index} className="slideDesc">
                  "{edge.node.contentText}"
                </p>
                <p key={"slideName" + index} className="slideName">
                  {edge.node.title}
                </p>
                <p key={"slideJob" + index} className="slideJob">
                  {edge.node.smallTitle}
                </p>
              </Slide>
            )
          })}
        </Slider>
        <div className="btnCarousel">
          <ButtonBack className="btnCarouselSvgLeft">
            <ChevronL className="chevronCarousel" />
          </ButtonBack>
          <ButtonNext className="btnCarouselSvgRight">
            <ChevronR className="chevronCarousel" />
          </ButtonNext>
        </div>
      </CarouselProvider>
    </CarouselClientsStyle>
  )
}

export default CarouselClients
