import React from "react"
//  eslint-disable-next-line
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import classNames from "classnames"

import MainTitle from "../components/mainTitle"
import CarouselClients from "../components/carouselClients"
// import CarouselNews from "../components/carouselNews"
// import BudgetEstimate from "../components/budgetEstimate"

import ScrollAnimation from "react-animate-on-scroll"

import { IndexStyle } from "../styles/index"
import { ClientsStyle } from "../styles/clients"
import { colors } from "../styles/variables"
import Arrow from "../images/arrow.svg"

/* SVG - Clients */
import Elecnor from "../images/clientes/elecnor.svg"
import Sonae from "../images/clientes/sonae.svg"
import Gestamp from "../images/clientes/gestamp.svg"
import Civilria from "../images/clientes/civilria.svg"
import Aleluia from "../images/clientes/new/aleluia-ceramicas.svg"
import Ambitermo from "../images/clientes/new/ambitermo.svg"
import Motrinde from "../images/clientes/new/motrinde.svg"
import Serimm from "../images/clientes/new/serimm.svg"
/* SVG - Clients */

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      height: 0,
      slideWidth: 0,
      slideHeight: 0,
      visibleSlides: 0,
      display: false,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
    window.addEventListener("knowmore", this.handleClick)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
    window.removeEventListener("knowmore", this.handleClick)
  }

  componentDidUpdate() {
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({ display: true })
  }

  render() {
    const {
      allAreas,
      about,
      clients,
      news, // eslint-disable-line
      clientsArea_tooltip, // eslint-disable-line
      budgetEstimate, // eslint-disable-line
    } = this.props.data.dataJson || {}

    return (
      <>
        <SEO
          title="Sicarpo"
          titleTemplate="%s"
          imageWidth="1200"
          imageHeight="628"
        />

        {this.props.data && this.props.data.dataJson ? (
          <IndexStyle>
            {/* Start - Initial Areas */}
            <div id="inicio" className="anchor"></div>
            <div className="allAreas">
              {allAreas.map(area => (
                <div className={"singleArea " + area.key} key={area.key}>
                  <Img
                    fluid={area.img.childImageSharp.fluid}
                    alt={area.img.childImageSharp.name}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="image"
                    style={{ position: "absolute" }}
                  />
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    offset={0}
                    duration={1.2}
                    delay={500}
                    animateOnce={true}
                  >
                    <h1>{area.title}</h1>
                    <p>{area.text}</p>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInDown"
                    offset={0}
                    duration={1.2}
                    delay={500}
                    animateOnce={true}
                    style={{ display: "flex" }}
                  >
                    <Link to={area.link} className="btnLink">
                      {area.buttonText}
                    </Link>
                  </ScrollAnimation>
                </div>
              ))}
            </div>
            {/* End   - Initial Areas */}

            {/* Start - Acerca da Sicarpo */}
            <div id="sobre" className="anchor"></div>
            <div className="about limitWidth bgDarker">
              <ScrollAnimation
                animateIn="fadeInLeft"
                offset={0}
                duration={1.2}
                animateOnce={true}
              >
                <MainTitle
                  smallText={about.mainTitle.smallText}
                  bigText={about.mainTitle.bigText}
                  colorSmall={colors.titleLightSmall}
                  colorBig={colors.titleLightBig}
                  className="title"
                />
                <ScrollAnimation
                  animateIn="fadeIn"
                  offset={0}
                  animateOnce={true}
                  delay={500}
                  duration={1.2}
                >
                  <div className="text">
                    <p>{about.text.firstParagraph}</p>
                    <button
                      onClick={this.handleClick}
                      className={classNames({
                        "about-button btn-visible": !this.state.display,
                        "about-button btn-hidden": this.state.display,
                      })}
                    >
                      <i className="material-icons icon-align">
                        keyboard_arrow_down
                      </i>
                    </button>
                    <p
                      className={classNames({
                        "know-visible": this.state.display,
                        "know-hidden": !this.state.display,
                      })}
                    >
                      {about.text.secondParagraph}
                    </p>
                  </div>
                </ScrollAnimation>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInRight"
                offset={0}
                duration={1.2}
                animateOnce={true}
                className="img"
              >
                <Img
                  fluid={about.img.src.childImageSharp.fluid}
                  alt={about.img.alt}
                  objectFit="cover"
                />
              </ScrollAnimation>
            </div>
            {/* End   - Acerca da Sicarpo */}

            {/* Start - Testemunhos de Clientes */}
            <div className="clients limitWidth bgLighter">
              <ScrollAnimation
                animateIn="fadeInRight"
                offset={0}
                duration={1.2}
                animateOnce={true}
              >
                <MainTitle
                  smallText={clients.mainTitle.smallText}
                  bigText={clients.mainTitle.bigText}
                  colorSmall={colors.titleDarkBig}
                  colorBig={colors.titleDarkSmall}
                  className="titleClients"
                />
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeIn"
                offset={0}
                delay={500}
                animateOnce={true}
              >
                <CarouselClients
                  naturalSlideWidth={
                    this.state.width < 768 && this.state.width >= 551
                      ? 120
                      : this.state.width < 550
                      ? 270
                      : 300
                  }
                  naturalSlideHeight={
                    this.state.width < 768 && this.state.width >= 551
                      ? 150
                      : this.state.width < 550
                      ? 220
                      : 300
                  }
                  visibleSlides={
                    this.state.width < 768 && this.state.width >= 551
                      ? 2
                      : this.state.width < 550
                      ? 1
                      : 3
                  }
                />
              </ScrollAnimation>
            </div>
            {/* End   - Testemunhos de Clientes */}
            {/* Start - Tooltip */}
            <div className="clients2 limitWidth bgDarker">
              <ScrollAnimation
                animateIn="fadeInRight"
                offset={0}
                duration={1.2}
                animateOnce={true}
              >
                <MainTitle
                  smallText={"Alguns dos nossos"}
                  bigText={clients.mainTitle.bigText}
                  colorSmall={colors.titleDarkBig}
                  colorBig={colors.titleDarkSmall}
                  className="titleClients noPaddingBottom"
                />
              </ScrollAnimation>
              <ClientsStyle noMarginTop>
                <ScrollAnimation
                  className="tooltipArea"
                  animateIn="fadeInUp"
                  offset={0}
                  duration={1.2}
                  animateOnce={true}
                >
                  <ul className="tooltipAllClients">
                    <li className="logoClient">
                        Aleluia Cerâmicas
                        <Aleluia />
                    </li>
                    <li className="logoClient">
                        Ambitermo
                        <Ambitermo />
                    </li>
                    <li className="logoClient">
                        Civilria
                        <Civilria />
                    </li>
                    <li className="logoClient">
                        Elecnor
                        <Elecnor />
                    </li>
                    <li className="logoClient">
                        Gestamp
                        <Gestamp />
                    </li>
                    <li className="logoClient">
                        Motrinde
                        <Motrinde />
                    </li>
                    <li className="logoClient">
                        Serimm
                        <Serimm />
                    </li>
                    <li className="logoClient">
                        Sonae
                        <Sonae />
                    </li>
                  </ul>
                  <Link to="/clientes" className="tooltipButtonContainer">
                    <span className="tooltipButton">
                      Todos os Clientes <Arrow className="tooltipButtonArrow" />
                    </span>
                  </Link>
                </ScrollAnimation>
              </ClientsStyle>
            </div>
            {/* End   - Tooltip */}

            {/* Start - Novidades */}
            {/* <ScrollAnimation
              animateIn="fadeInLeft"
              offset={0}
              duration={0.75}
              animateOnce={true}
            >
              <div id="noticias" className="anchor"></div>
              <div className="news bgDarker">
                <ScrollAnimation
                  animateIn="fadeIn"
                  offset={0}
                  delay={500}
                  animateOnce={true}
                >
                  <MainTitle
                    smallText={news.mainTitle.smallText}
                    bigText={news.mainTitle.bigText}
                    colorSmall={colors.titleDarkBig}
                    colorBig={colors.titleDarkSmall}
                    className="title"
                  />
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeIn"
                  offset={0}
                  delay={750}
                  animateOnce={true}
                  style={{ overflow: "visible" }}
                >
                  <CarouselNews
                    naturalSlideWidth={
                      this.state.width < 768 && this.state.width >= 551
                        ? 120
                        : this.state.width < 550
                        ? 270
                        : 300
                    }
                    naturalSlideHeight={
                      this.state.width < 768 && this.state.width >= 551
                        ? 150
                        : this.state.width < 550
                        ? 220
                        : 300
                    }
                    visibleSlides={
                      this.state.width < 768 && this.state.width >= 551
                        ? 2
                        : this.state.width < 550
                        ? 1
                        : 3
                    }
                  />
                </ScrollAnimation>
              </div>
            </ScrollAnimation> */}
            {/* End   - Novidades */}

            {/* Start - Soluções à Medida / Budget */}
            {/* <BudgetEstimate
              bigText={budgetEstimate.mainTitle.bigText}
              bigText2={budgetEstimate.mainTitle.bigText2}
              colorBig={colors.titleLightBig}
              className="titleBudget"
              h1="titleBudgetH1"
              firstParagraph={budgetEstimate.text.firstParagraph}
            /> */}
            {/* End   - Soluções à Medida / Budget */}
          </IndexStyle>
        ) : (
          <div className="isLoading">A carregar...</div>
        )}
      </>
    )
  }
}

export default IndexPage

export const IndexQuery = graphql`
  query IndexQuery {
    dataJson {
      allAreas {
        img {
          name
          childImageSharp {
            fluid(maxWidth: 2560, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        text
        link
        buttonText
        key
      }
      about {
        mainTitle {
          smallText
          bigText
        }
        text {
          firstParagraph
          secondParagraph
        }
        img {
          src {
            name
            childImageSharp {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
        }
      }
      clients {
        mainTitle {
          smallText
          bigText
        }
      }
      clientsArea_tooltip {
        title
      }
      news {
        mainTitle {
          smallText
          bigText
        }
      }
      budgetEstimate {
        mainTitle {
          smallText
          bigText
          bigText2
        }
        text {
          firstParagraph
        }
      }
    }
  }
`
