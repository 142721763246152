import styled from "styled-components"
import { colors, aspectratio } from "./variables"

export const CarouselClientsStyle = styled.div`
  background-color: ${colors.darkGrey};

  div.carousel {
    height: ${aspectratio.carouselHeightClients};
    position: relative;

    div.carousel__slider {
      height: 100%;

      &:focus {
        outline: 0;
      }

      li.slide {
        img,
        p {
          display: block;
        }
      }
    }

    div.carousel__inner-slide {
      display: grid;
      grid-template-rows: minmax(0, 50%) minmax(0, 20%) minmax(0, 5%) minmax(
          0,
          10%
        );
      grid-row-gap: 5%;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }
  }

  .logoClient {
    background-color: ${colors.whiteBg};
    border-radius: 50%;
    width: 7vw;
    height: 7vw;
    padding: 2.2vw;
    justify-self: center;
    align-self: end;

    img {
      height: 100%;
      width: 100%;
    }
  }
  .slideDesc {
    width: 80%;
    font-family: Barlow;
    font-size: 1.6rem;
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    justify-self: center;
  }
  .slideName {
    font-family: Barlow;
    font-size: 1.4rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    position: relative;
    justify-self: center;
  }
  .slideJob {
    width: 65%;
    font-family: Barlow;
    font-size: 1.3rem;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    justify-self: center;
  }

  .btnCarousel {
    width: 10.4vw;
    height: 3.23vw;
    box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
    background-color: ${colors.orange};
    position: absolute;
    left: 6vw;
    bottom: -1.615vw;

    button:focus {
      outline: 0;
    }
  }
  .btnCarouselSvgLeft {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    width: 50%;
    padding-left: 2vw;
    height: 100%;
    transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;

    &:hover {
      background-color: ${colors.orangeDark};
    }
  }
  .btnCarouselSvgRight {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    width: 50%;
    padding-right: 2vw;
    height: 100%;
    transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    -moz-transition: background-color 0.5s ease;
    -webkit-transition: background-color 0.5s ease;

    &:hover {
      background-color: ${colors.orangeDark};
    }
  }

  .chevronCarousel {
    height: 50%;
    object-fit: contain;
    transform: translateY(2px);
  }

  @media screen and (max-width: 1440px) {
    .slideDesc {
      font-size: 1.6rem;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    div.carousel {
      height: 38vw;
    }

    .slideDesc {
      font-size: 1.4rem;
    }
    .slideName {
      font-size: 1.3rem;
    }
    .slideJob {
      font-size: 1.2rem;
    }
    .btnCarousel {
      width: 15%;
      height: 35px;
      bottom: -4.25%;
      left: 10%;
    }
  }
  @media screen and (min-width: 551px) and (max-width: 767px) {
    div.carousel {
      height: 65vw;

      div.carousel__inner-slide {
        grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, auto) minmax(
            0,
            auto
          );
        grid-row-gap: 2%;
      }
    }
    .logoClient {
      width: 18vw;
      height: 18vw;
    }
    .slideDesc {
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.28;
    }
    .slideName {
      font-size: 1.3rem;
      width: 100%;
    }
    .slideJob {
      font-size: 1.2rem;
      width: 70%;
    }
    .btnCarousel {
      width: 20%;
      height: 35px;
      bottom: -4.25%;
      left: 10%;
    }
  }
  @media screen and (max-width: 550px) {
    div.carousel {
      height: 85vw;

      div.carousel__inner-slide {
        grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, auto) minmax(
            0,
            auto
          );
        grid-row-gap: 2%;
      }
    }

    .logoClient {
      width: 25vw;
      height: 25vw;
    }
    .slideDesc {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.28;
    }
    .slideName {
      font-size: 1.2rem;
      width: 100%;
    }
    .slideJob {
      font-size: 1.1rem;
      width: 70%;
    }
    .btnCarousel {
      width: 90px;
      height: 35px;
      bottom: -4.25%;
      left: 10%;
    }
  }
`
